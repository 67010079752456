<template>
  <div>
    <p class="title is-3">Adauga</p>
    <p class="subtitle is-5">Persoana Juridica</p>

    <div class="pb-3">
      <b-button @click="back"
                icon-pack="mdi"
                icon-left="arrow-left">Inapoi
      </b-button>
    </div>

    <FormularPersoaneJuridice @personDataChanged="updatePersonData" />

    <b-button type="is-primary" class="mt-3" @click="adaugaPersoanaJuridica">Adauga</b-button>


  </div>
</template>

<script>
import router from "@/router";
import FormularPersoaneJuridice from "./FormularPersoaneJuridice";
import { api } from "@/network";

export default {
  name: "AdaugaPersoanaJuridica",
  components: { FormularPersoaneJuridice },
  data() {
    return {
      datePersoana: {},
    };
  },
  methods: {
    adaugaPersoanaJuridica() {
      if (!this.datePersoana.rol.length || !this.datePersoana.rol_vechi.length || !this.datePersoana.denumire.length) {
        this.$buefy.toast.open({
          message: "Rol, rol vechi, denumire sunt obligatorii!",
          type: "is-danger",
        });
      } else {
        api
          .post("add-person", { person: this.datePersoana })
          .then(() => {
            this.$buefy.toast.open({
              message: "Persoana fizica adaugata cu succes!",
              type: "is-success",
            });

            router.push("/administrare/persoane-juridice");
          })
          .catch(() => {
            this.$buefy.toast.open({
              message: "A fost o eroare in adaugarea de persoana. Va rugam incercati mai tarziu sau contactati un administrator.",
              type: "is-danger",
            });
          });
      }
    },
    back() {
      router.push("/administrare/persoane-juridice");
    },
    updatePersonData(personData) {
      this.datePersoana = personData;
    },
  },
};
</script>

<style scoped>

</style>